<div *ngIf="!route?.url?.includes('/portal')">
  <owl-carousel-o
    #owlCar
    class="owl-lazy owl-centered owl-loading owl-responsive"
    [options]="customOptions"
  >
    <ng-container *ngFor="let item of data">
      <ng-template carouselSlide>
        <div class="no-border radius-05">
          <div class="sectionbody">
            <div class="icon">
              <!-- <img [src]="item?.img" class="img-fluid" /> -->
              <div
                class="news-img w-100"
                [style.backgroundImage]="'url(' + item?.img + ')'"
              ></div>
            </div>
            <div class="caption">
              <h5 class="title fs-40 text-white">{{ item?.title }}</h5>
              <p class="subTitle">Lorem ipsum Dolores</p>
              <p class="discription">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
                commodo ligula eget dolor. Aenean massa. Cum sociis natoque
                penatibus et magnis dis parturient montes, nascetur ridiculus
                mus. Nullam dictum felis eu pede mollis pretium. Integer
                tincidunt ...
              </p>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
</div>
<div *ngIf="route?.url?.includes('/portal')" id="landingOWL">
  <owl-carousel-o
    #owlCar
    class="owl-lazy owl-centered owl-loading owl-responsive"
    [options]="customOptions"
  >
    <ng-container *ngFor="let item of data">
      <ng-template carouselSlide>
        <div class="no-border radius-05">
          <div
            class="sectionbody sectionbody-landing d-flex flex-column flex-lg-row"
          >
            <div class="icon h">
              <!-- <img [src]="item?.img" class="img-fluid" /> -->
              <!-- (click)="openFullWidthImage(item)" -->
              <div
                class="news-landing-img w-100"
                [style.backgroundImage]="'url(' + item?.img + ')'"
              ></div>
            </div>
            <!-- (click)="openFullWidthText(item)" -->
            <div class="caption caption-landing w-100 py-0 pt-3">
              <div [innerHTML]="item.title"></div>
              <!-- <p class="subTitle">{{ item?.subTitle }}</p> -->
              <!-- <p class="discription">
                {{ item?.details }}
              </p> -->
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
</div>
<button
  type="button"
  class="btn btn-primary"
  data-bs-toggle="modal"
  data-bs-target="#bd-example-modal-xl"
  #fullWidthButton
  hidden
>
  Extra large modal
</button>

<div
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-bs-labelledby="myExtraLargeModalLabel"
  aria-bs-hidden="true"
  id="bd-example-modal-xl"
  data-bs-backdrop="static"
>
  <div class="modal-dialog modal-xl">
    <div class="modal-content"><img [src]="image" /></div>
  </div>
</div>

<button
  type="button"
  class="btn btn-primary"
  data-bs-toggle="modal"
  data-bs-target="#text-example-modal-xl"
  #fulltextButton
  hidden
>
  Extra large modal
</button>

<div
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-bs-labelledby="myExtraLargeModalLabel"
  aria-bs-hidden="true"
  id="text-example-modal-xl"
  data-bs-backdrop="static"
>
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="caption caption-landing w-100 py-0 pt-3">
      <div [innerHTML]="slid?.title"></div>
      <!-- <p class="subTitle">{{ slid?.subTitle }}</p> -->
      <!-- <p class="discription">
        {{ slid?.details }}
      </p> -->
    </div>
  </div>
</div>
