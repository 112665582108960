export const environment = {
  apiUrl: 'https://api.portal.dev.kommunity.projects.kogo4ic.de/v1',
  authUrl:
    'https://keycloak.dev.kommunity.projects.kogo4ic.de/auth/realms/master',
  baseAuthUrl: 'https://keycloak.dev.kommunity.projects.kogo4ic.de/auth',
  weatherKey: 'ab51445e151c4315aa1113251242105',
  LocationIQ: 'pk.698afc821f679b7a16959cbc81c46220',
  userApis:
    'https://user-management.dev.kommunity.projects.kogo4ic.de/v1/private',
};
