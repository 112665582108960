import { Component, Inject, Input, OnInit } from '@angular/core';
import { ProjectCardComponent } from './project-card/project-card.component';
import { CommonModule } from '@angular/common';
import { ProjectsService } from '../../../services/projects.service';
import { App } from '../../../_models/app.model';
import { CarouselModule, OwlOptions } from 'ngx-owl-carousel-o';
import { NgxPaginationModule } from 'ngx-pagination';
import { RouterLink } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { IUser } from '../../../_models/login.model';
import { AuthService } from '../../../auth/services/auth.service';
import { TranslateModule } from '@ngx-translate/core';
import { jwtDecode } from 'jwt-decode';

@Component({
  selector: 'app-projects',
  standalone: true,
  imports: [
    ProjectCardComponent,
    CommonModule,
    CarouselModule,
    NgxPaginationModule,
    RouterLink,
    TranslateModule
  ],
  templateUrl: './projects.component.html',
  styleUrl: './projects.component.scss',
})
export class ProjectsComponent implements OnInit {
  @Input({ required: true }) byEmeis!: boolean;
  totalCount!: number;
  pageNumber: number = 1;
  pageSize: number = 4;
  projects!: App[];
  userData!: IUser;
  userRoles!: any;
  paginate(event: number) {
    this.pageNumber = event;
  }

  constructor(
    private projectService: ProjectsService,
    private oidcSecurityService: OidcSecurityService,
    private authService: AuthService
  ) {}
  ngOnInit(): void {
    // this.getUserRoles();
    this.getUserData();
  }

  getAppByUserId() {
    let userId = this.userData.sub.split("_")[1];
    console.log(`userId: ${userId}`,this.userData.sub)
    this.projectService
      .getApplicationsByUserId(
        userId
      )
      .subscribe({
        next: (data) => {
          this.projects = data.filter((project: App) => {
            return project.isActive == true;
          });

          this.totalCount = this.projects?.length;
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  getUserRoles() {
    // this.userRoles = this.authService.getUserRoles();
    console.log(this.userRoles.includes('admin'));
    if (this.userRoles) this.getUserData();
  }
  getAppsByEmeis() {
    this.projectService.getStaticApps().subscribe((data) => {
      this.projects = data.filter((project) => {
        return project.isActive == true;
      });

      this.totalCount = this.projects?.length;
    });
  }

  getAppsForUser() {
    this.projectService.getStaticAppsForUser().subscribe((data) => {
      this.projects = data;
      this.totalCount = data?.length;
    });
  }
  getUserData() {
    this.oidcSecurityService.getIdToken().subscribe((data) => {
      const decodedToken:any = jwtDecode(data);

      this.userData = decodedToken;
      console.log(this.userData);
      
      this.getAppByUserId();
      // if (this.byEmeis == true && this.userRoles.includes('admin')) {
      //   this.callGetApplicationsForAdmin();
      // } else if (this.byEmeis == true && !this.userRoles.includes('admin')) {
      //   this.callGetApplicationsForUser();
      // }
      // if(this.byEmeis == true){
      //   this.getAppsForUser();
      // }
      // else {
      //   this.getAppsForUser();
      // }
    });
  }

  // Method to call getApplicationsForAdmin
  callGetApplicationsForAdmin() {
    this.projectService.getApplicationsForAdmin().subscribe((data) => {
      this.projects = data.filter((project: App) => {
        return project.isActive == true;
      });

      this.totalCount = this.projects?.length;
    });
  }

  // Method to call getApplicationsForUser
  callGetApplicationsForUser() {
    this.projectService.getApplicationsForUser().subscribe((data) => {
      this.projects = data.filter((project: App) => {
        return project.isActive == true;
      });

      this.totalCount = this.projects?.length;
    });
  }

  //method to fetch projects for user
  // getProjectsByUserName() {
  //   this.projectService
  //     .getProjectsByUserName(this.userData.preferred_username)
  //     .subscribe((data) => {
  //       this.projects = data.filter((project: App) => {
  //         return project.isActive == true;
  //       });

  //       this.totalCount = this.projects?.length;
  //     });
  // }
}
