import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { App } from '../_models/app.model';
import { AppConfigService } from './app-config.sefvice';

@Injectable({
  providedIn: 'root',
})
export class ProjectsService {
  auth!: Observable<Boolean>;
  constructor(private http: HttpClient, private appConfigService: AppConfigService) {}

  getStaticApps() {
    const apiUrl = this.appConfigService.apiUrl 

    return this.http.get<App[]>(apiUrl + '/applications');
  }
  getStaticAppsForUser() {
    
    return this.http.get<any>('/assets/files/applications.json');
  }
  // Fetch applications with admin scope
  getApplicationsForAdmin() {
    const apiUrl = this.appConfigService.apiUrl 

    return this.http.get<any>(
      apiUrl + '/applications/filter?assignedRole=admin'
    );
  }

  getApplicationsByUserId(userId: string) {
    const apiUrl = this.appConfigService.apiUrl 

    return this.http.get<App[]>(
      apiUrl + `/applications/user/${userId}`
    );
  }
  // Fetch applications with user scope
  getApplicationsForUser() {
    const apiUrl = this.appConfigService.apiUrl 

    return this.http.get<any>(
      apiUrl + '/applications/filter?assignedRole=user'
    );
  }

  //fetch projects for user
  getProjectsByUserName(userName: string) {
    const apiUrl = this.appConfigService.apiUrl 

    return this.http.get<any>(
      apiUrl + `/projects/filter?assignedUser=${userName}`
    );
  }

  getUserAppsByCategoryId(userId:string,categoryId:string){
    const apiUrl = this.appConfigService.apiUrl 

     return this.http.get<any>(
      apiUrl + `/applications/user/${userId}?categoryId=${categoryId}`
    );
  }
}
