import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-news',
  standalone: true,
  imports: [CommonModule, CarouselModule],
  templateUrl: './news.component.html',
  styleUrl: './news.component.scss',
})
export class NewsComponent {
  image!: string;
  @ViewChild('fullWidthButton') fullWidthButton!: ElementRef;
  @ViewChild('fulltextButton') fulltextButton!: ElementRef;
  slid!: any;
  constructor(public route: Router, public sanitizer: DomSanitizer) {}
  data: any[] = [
    {
      img: '../../../assets/ÖGD-Logo1.png',
      title:
        '<span class="fs-32 title">Funktionstests von HBSN gestartet</span> <br/> <span >15.07.2024 </span> Die Funktionstests für die ESU-Applikation sind erfolgreich gestartet. Diese Tests sind ein wichtiger Schritt, um sicherzustellen, dass das System reibungslos arbeitet und alle wichtige Funktionalitäten zur Verfügung stehen und genutzt werden können.',
      details: '',
    },
    {
      img: '../../../assets/ÖGD-Logo1.png',
      title:
        '<span class="fs-32 title">Komm.Unity Core Portallösung als zentraler Einstieg für alle Ämter gelauncht</span> <br/> <span>15.07.2024 –</span>  Komm.Unity Core Portallösung wird demnächst offiziell als zentraler Einstiegspunkt für alle Gesundheitsämter eingeführt. Diese neue Plattform bietet eine benutzerfreundliche Oberfläche, die den Zugang zu verschiedenen Verwaltungsdiensten vereinfacht und effizienter gestaltet. Mehr Informationen findet ihr unter dem Link "Über Komm.Unity" unter ihrem Profil.',
      details: '',
    },
    {
      img: '../../../assets/ÖGD-Logo1.png',
      title:
        '<span class="fs-32 title">Klausurtagung findet am 17.07. statt</span> <br/> <span>15.07.2024 –</span> Die nächste Klausurtagung wird am 17.07.2024 stattfinden. Diese Tagung bietet eine wichtige Gelegenheit für alle Beteiligten, sich über aktuelle Entwicklungen zu informieren, strategische Entscheidungen zu treffen und die zukünftige Ausrichtung zu planen.',
    },
  ];

  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 900,
    navText: ['', ''],
    margin: 10,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1,
      },
      1024: {
        items: 1,
      },
    },
    nav: false,
  };

  openFullWidthImage(item: any) {
    console.log(item);

    this.image = item?.img;
    this.fullWidthButton.nativeElement.click();
    const elem = document.getElementById('bd-example-modal-xl');
    if (elem?.requestFullscreen) {
      elem.requestFullscreen();
    } else if ((<any>elem).mozRequestFullScreen) {
      // Firefox
      (<any>elem).mozRequestFullScreen();
    } else if ((<any>elem).webkitRequestFullscreen) {
      // Chrome, Safari and Opera
      (<any>elem).webkitRequestFullscreen();
    } else if ((<any>elem).msRequestFullscreen) {
      // IE/Edge
      (<any>elem).msRequestFullscreen();
    }
  }
  openFullWidthText(item: any) {
    console.log(item);

    this.slid = item;
    this.fulltextButton.nativeElement.click();
    const elem = document.getElementById('text-example-modal-xl');
    if (elem?.requestFullscreen) {
      elem.requestFullscreen();
    } else if ((<any>elem).mozRequestFullScreen) {
      // Firefox
      (<any>elem).mozRequestFullScreen();
    } else if ((<any>elem).webkitRequestFullscreen) {
      // Chrome, Safari and Opera
      (<any>elem).webkitRequestFullscreen();
    } else if ((<any>elem).msRequestFullscreen) {
      // IE/Edge
      (<any>elem).msRequestFullscreen();
    }
  }
  getSanitizedTitle(title: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(title);
  }

  closeSlide(): void {
    this.slid = null;
  }
}
